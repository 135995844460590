@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@tailwind base;
@tailwind components;

@layer base {
  html {
    @apply text-black;
  }

  body {
    font-family: "DM Sans", sans-serif;
  }

  main {
    min-height: calc(100vh - 112px);
  }

  .uploadVideo {
    height: calc(100vh - 62px);
  }

  aside::before {
    @apply absolute left-full top-0 h-full w-screen bg-transparent-7 content-[''];
  }

  @media screen(md) {
    header {
      width: calc(100% - 116px);
    }

    main {
      height: calc(100vh - 136px);
    }

    aside::before {
      @apply content-none;
    }
  }
}

@layer components {
  aside .menu.active {
    @apply bg-accent-darker;
  }

  aside.active {
    @apply left-0;
  }

  .user-profile label {
    @apply bg-white;
  }

  .files {
    gap: clamp(1rem, 0.3654rem + 2.8205vw, 3.75rem);
  }

  .file {
    height: clamp(2.75rem, 2.1154rem + 2.8205vw, 5.5rem);
  }

  .file-move {
    height: clamp(2.25rem, 1.9615rem + 1.2821vw, 3.5rem);
  }

  .file-move h5 {
    font-size: clamp(0.875rem, 0.8173rem + 0.2564vw, 1.125rem);
  }

  .file h5 {
    font-size: clamp(0.875rem, 0.7885rem + 0.3846vw, 1.25rem);
  }

  .default3dmodel {
    height: calc(100vh - 226px);
  }

  .edit3dmodel {
    height: calc(100vh - 193px);
  }

  @media screen(md) {
    .blank-btns {
      max-width: calc(100% - 100px);
    }
  }

  @media screen(lg) {
    .user-profile label {
      @apply bg-accent-lighter;
    }
  }
}

@tailwind utilities;

@layer utilities {
  .bottom-drawer {
    bottom: clamp(-19.375rem, -19.0865rem + -1.2821vw, -20.625rem);
  }
}

.errormsg {
  display: flex;
  align-items: center;
  border-radius: 1rem;
}

.ms-auto {
  margin-left: auto;
}

.closeIconBox {
  margin-left: auto;
}

.msgTextBox {
  display: flex;
  align-items: center;
}

.msgTitle {
  font-weight: 600;
}

.discardTxt {
  font-weight: 600;
  text-decoration: underline;
}

.Ellipse-59 {
  width: 8px;
  height: 8px;
  margin: 5px 8px 5px 0;
  background-color: #f44336;
  border-radius: 20px;
}

.statusBtnBox {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  padding: 7px 15px;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
}

.statusBtnBox::before {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  margin: 5px 8px 5px 0;
  border-radius: 20px;
}

.statusBtnBox.success {
  background: #EDF5F1;
  color: #137359;
}

.statusBtnBox.success::before {
  background-color: #137359;
}

.statusBtnBox.danger {
  background: #FF6B613B;
  color: #f44336;
}

.statusBtnBox.danger::before {
  background-color: #f44336;
}

.actionBtngrp {
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #C2D4CE;
  white-space: nowrap;
}

.actionBtn {
  display: inline-block;
  padding: 8px 14px;
  line-height: 1;
  border-right: 1px solid #C2D4CE;
  font-weight: 700;
}

.actionBtn:last-child {
  border-right: none;
}

.ppHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
}

.inv-ppHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
  height: 35px;
}

.statusbx {
  position: absolute;
  right: 60px;
  top: 15px;
}

.statusBtn {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 9px 12px;
  border-radius: 10px;
  background-color: rgba(255, 107, 97, 0.23);
  color: #f44336;
  font-weight: 600;
}

.dataRow {
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 1px;
  justify-content: space-between;
  border-bottom: solid 1px #c2d4ce;
  margin-bottom: 20px;
}

.dataCol {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  width: 33.33%;
  flex: 1 0 auto;
  margin-bottom: 30px;
  gap: 8px;
}

.dataCol3 {
  width: 20%;
}

.invoiceBlock {
  margin: 20px 0 0;
  padding: 9px 10px 10px;
  border-radius: 20px;
  border: solid 1px #c2d4ce;
  background-color: #fff;
}

.dataUl {
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  list-style-type: none;
}

.dataUl li {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  width: 33.33%;
  margin-bottom: 30px;
  gap: 8px;
}

.dataTitle {
  font-size: 16px;
  color: #999;
}

.dtaDiv {
  font-size: 16px;
  font-weight: 600;
  color: #444;
}

.invoiveTable {
  display: table;
  width: 100%;
}

.invoiveTable tr th,
.invoiveTable tr td {
  text-align: left;
  padding: 10px 15px;
}

.invoiveTable tr th {
  background-color: #edf5f1;
}

.invoiveTable tr th:first-child {
  border-radius: 14px 0 0 14px;
}

.invoiveTable tr th:last-child {
  border-radius: 0 14px 14px 0;
}

.sumTableBlock {
  border-radius: 14px;
  background-color: #edf5f1;
  overflow: hidden;
}

.sumTable {
  display: table;
  width: 100%;
}

.sumTable tr th,
.sumTable tr td {
  text-align: left;
  padding: 10px 15px;
}

.sumTable tr {
  border-bottom: solid 1px #c2d4ce;
}

.sumTable tr:last-child {
  border-bottom: 0;
}

.sumTable tr td {
  background-color: #edf5f1;
}

.dataFooter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 25px;
  border-top: solid 1px #c2d4ce;
}

.paymentPendingBox {
  background: #ff2a2a2e;
  padding: 20px;
  padding-left: 40px;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  position: relative;
}

.paymentPendingBox.v2 {
  padding-left: 50px;
}

.Pending-title {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1.2rem;
  font-weight: 600;
}

.Pending-title.v2 {
  font-size: 16px;
}

.Pending-text {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.payBtn {
  color: red;
  font-weight: 500;
  text-decoration: underline;
}

.warning-mark {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
}

.Pending-title.v2 .warning-mark {
  position: absolute;
  left: 20px;
  width: 20px;
  height: 20px;
}

.card-warning {
  position: absolute;
  z-index: 33;
  top: 11px;
  right: 0;
}

.myModal1a {
  background: linear-gradient(123.03deg, #E6F4EE 15.23%, #C8F1E2 48.6%, #E6F4EE 85.37%);
  box-sizing: border-box;
}

.pop-content-body {
  color: #555;
}

.pop-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-msg-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  padding: 20px 0;
}

.popmsgtxtbx {
  padding: 0 50px;
  font-size: 14px;
}

.fs14 {
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.pop-msgttx-box {
  margin-bottom: 20px;
}

.popul {
  padding-left: 15px;
  margin-left: 15px;
}

.popul li {
  list-style-type: disc;
  font-weight: 700;
  font-size: 14px;
}

.ulink {
  font-weight: 600;
  text-decoration: underline;
}

.invoice-popup-content-body {
  background: url("../public/images/invbg.png") no-repeat bottom center;
  background-size: 100%;
}

.h-500 {
  height: 500px;
}

.h-400 {
  height: 400px;
}

.invpop {
  background: #fff url("../public/images/webapp-Invoice-bg.png") no-repeat top left;
}

.invsdtlbox {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  box-sizing: border-box;
  font-size: 13px;
}

.blki {
  box-sizing: border-box;
  width: 50%;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
}

.compadd {
  padding-top: 20px;
  color: #666;
}

.invdtlul li {
  display: block;
  padding: 5px 0;
}

.invdtlul li span {
  display: inline-block;
  min-width: 100px;
}

.blk3 {
  padding-top: 20px;
}

.invttx {
  font-size: 30px;
  font-weight: 600;
  text-align: right;
  display: block;
}

.tabledtlbx {
  padding: 20px;
}

.invtable {
  width: 100%;
  margin: 0;
  padding: 0;
}

.invtable tr {
  margin: 0;
  padding: 0;
}

.invtable tr th {
  background: #8fd7b3;
}

.invtable tr td,
.invtable tr th {
  padding: 10px;
}

video#bannerVideo {
  width: 520px;
  height: 300px;
  border-radius: 20px;
  background: white;
}

.align-items-center {
  align-items: center;
}

.mymod {
  height: 600px;
}

.delet {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
}

.promod {
  padding: 50px 15px 15px 15px
}

.hdd1 {
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  margin-bottom: 30px;
}

.modimgBx {
  position: relative;
  /* height: 320px; */
  height: clamp(200px, 40vh, 300px)
}

iframe.modimg {
  height: 100%;
  width: 100%;
}

.modimg {
  border-radius: 30px;
}

.infor {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7%;
  right: 7%;
  background: #fff;
  color: #F44336;
  padding: 10px 8px;
  text-align: center;
  z-index: 1;
  font-weight: 500;
}

.tutorialfrontvdo {
  height: auto !important;
}

.newuserDashboardRow {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 40px;
}

.newuserDashboardItemBx {
  width: calc(25% - 30px);
}

.newuserDashboardItem {
  position: relative;
  z-index: 1;
}

.newuserDashboardItem.item2::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: #fff;
  z-index: -1;
  border-radius: 30px;
}

img.modimg1 {
  max-height: 100%;
  margin: 0 auto;
  height: clamp(175px, 36vh, 200px);
}

.cardbtm {
  padding: 15px 20px 20px;
}

.optbtngrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px 0;
}

.dotbtn {
  flex-shrink: 0;
}

.btnoutline {
  display: block;
  padding: 6px 15px;
  text-align: center;
  background: transparent;
  border-radius: 12px;
  border: 1px solid #137359;
  color: #137359;
  font-size: 1rem;
  font-weight: 500;
}

.modimgBx1 {
  position: relative;
}

.ddnpop {
  background: linear-gradient(123.03deg, #E6F4EE 15.23%, #C8F1E2 48.6%, #E6F4EE 85.37%);
  width: 60vw !important;
  min-height: unset;
}

.ddnpopbox {
  display: block;
}

.onDesktop {
  display: flex;
  gap: 30px;
}

.onDesktopview {
  display: block;
}

.ddnpopbox1 {
  display: block;
}

.llft,
.llrt {
  width: calc(50% - 15px);
  padding: 30px 33px 22px 34px;
  border-radius: 30px;
  border: 1px solid rgba(194, 212, 206, 1);
  background: #fff;
}

.llft1,
.llrt1 {
  width: 100%;
  padding: 30px 33px 22px 34px;
  border-radius: 30px;
  border: 1px solid rgba(194, 212, 206, 1);
  background: #fff;
}

.ddnttl {
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  color: rgba(68, 68, 68, 1);
  margin-bottom: 10px;
}

.ddnimg {
  cursor: pointer;
}

.onMobdiv {
  display: none;
}

.ddnsldr .slick-dots li.slick-active button {
  background-color: rgba(143, 215, 179, 1);
  width: 16px;
  height: 4px;
  border-radius: 10px;
  padding: 0;
}

.ddnsldr .slick-dots li button {
  background-color: rgba(143, 215, 179, 1);
  width: 4px;
  height: 4px;
  border-radius: 10px;
  padding: 0;
}

.ddnsldr .slick-dots li button::before {
  display: none;
}

.ddnsldr .slick-dots li {
  width: unset;
  height: unset;
}

@media (max-width:1420px) {
  img.modimg1 {
    max-height: 200px;
    margin: 0 auto;
    object-fit: contain;
    margin-bottom: -20px;
  }

}

.prime_top-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 55px;
  /* padding: 45px 100px; */
  border-radius: 16px;
  background: linear-gradient(96.09deg, rgba(75, 206, 139, 0.58) 3.31%, rgba(162, 215, 144, 0.534091) 36.49%, rgba(162, 215, 144, 0.51) 64.94%, rgba(75, 206, 139, 0.35) 94.32%);
  position: relative;
}

.prime_top-card-text {
  font-family: DM Sans;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
}

.prime_top-card-btn {
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  color: #FFFFFF;
  padding: 10px 30px;
  border-radius: 16px;
  background: #137359;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}

.prime_st {
  height: 50px;
  width: 50px;
  position: absolute;
}

.prime_st2 {
  height: 40px;
  width: 40px;
  position: absolute;
}

.star1 {
  top: 8px;
  left: 45px;
}

.star2 {
  bottom: 5px;
  left: 15px;
}

.star3 {
  top: 5px;
  right: 40px;
}

.star4 {
  bottom: 2px;
  right: 11px;
}


/* .star1 {
  top: 8px;
  left: 90px;
}

.star2 {
  bottom: 10px;
  left: 40px;
}

.star4 {
  bottom: 2px;
  right: 30px;
}

.star3 {
  top: 5px;
  right: 65px;
} */

.prime_horseImg {
  width: 100%;
  margin-top: 30px;
}

.prime_horseImg-mob {
  width: 100%;
  display: none;
}

.prime_horseImg-web {
  width: 100%;
  display: block;
}

.getEnterprise_btn {
  background: linear-gradient(96.09deg, rgba(75, 206, 139, 0.58) 3.31%, rgba(162, 215, 144, 0.534091) 36.49%, rgba(162, 215, 144, 0.51) 64.94%, rgba(75, 206, 139, 0.35) 94.32%);
  border-radius: 16px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 270px;
  margin-right: 20px;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  color: #444444;
  justify-content: center;
  text-align: center;
}

.starbtn {
  height: 25px;
}

.mmdiv {
  display: flex;
  flex-direction: column;
}

@media (min-width:768px) {
  .mmdiv {
    height: calc(100vh - 25px);
  }

  .crdno {
    font-size: 14px;
  }

  .perm {
    font-family: DM Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.93px;
    text-align: center;
  }
}

.btnFill,
.btnOutline {
  align-items: center;
  border: 1px solid #137359;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: row;
  height: 56px;
  justify-content: center;
  min-width: 150px;
  outline: none;
  padding: 8px 15px;
}

.btnFill {
  background-color: #137359;
  color: #fff;
}

.fttblkcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ftrinst {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.popfoottr {
  padding: 15px;
  background: #fff;
  border-radius: 0 0 1.5rem 1.5rem;
}

.invpop svg.cursor-pointer {
  z-index: 111;
}

.credbx {
  margin-right: 15px;
}

.credbtn {
  width: 220px;
  height: 3.2rem;
  border-radius: 16px;
  border: 1px solid #C2D4CE;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem;
  font-weight: 500;
  color: #444;
  background: #EDF5F1;

}

.connbx {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pricingheading {
  font-weight: 700;
  font-size: 2.125rem;
}

.greentxt {
  font-size: 1.5rem;
  font-weight: 500;
  color: #137359;
}

.stageBar {
  margin-top: 30px;
  margin-bottom: 25px;
}

.stageBarrow {
  display: flex;
  margin-top: 10px;
  position: relative;
}

.stageBarBlock {
  width: 25%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccount {
  font-size: 1.5rem;
  font-weight: 500;
  color: #137359;
}

.stageprogressBar {
  display: block;
  content: " ";
  width: 100%;
  height: 6px;
  background: #C2D4CE;
  border-radius: 3px;
  position: relative;
}

.stageprogressBarFill {
  display: block;
  width: 10%;
  height: 6px;
  background: #137359;
  position: absolute;
  border-radius: 3px;
}

.stageprogressBarFill::after {
  display: block;
  content: " ";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #137359;
  position: absolute;
  top: -7px;
  right: -10px;
}


.stageBlockrow {
  display: flex;
  gap: 20px;
}

.stageBloc {
  width: calc(25% - 10px);
}

.stageBlocCard {
  box-shadow: 6px 6px 20px 0px #18886924;
  border-radius: 30px;
  display: block;
  width: 100%;
  background: #fff;
  text-align: center;
}

.stageBlocCard.active {
  border: solid 1px #2D866D;
}

.hhdright {
  justify-content: flex-end;
}

.crdno {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 20px;
}

.pprc {
  color: #2D866D;
}

.pprc span {
  font-size: 4rem;
  font-weight: 700;
}

#userProfile-countryCode+label {
  background-color: rgb(237 245 241 / var(--tw-bg-opacity));
}

.acrd-fttr {
  background: #DBF3E9;
  border-radius: 0 0 30px 30px;
  padding: 15px 10px 15px 20px;
  display: block;
}

.fttrli {
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #444;
  list-style-type: disc;
  padding-left: 20px;
}

.pprc {
  color: #2D866D;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.pprc sup {
  top: -2.1rem;
}

.contdtl {
  width: 246px;
  height: 75px;
  border-radius: 14px;
  border: 1px solid #8FD7B3;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.septr {
  height: 1px;
  box-shadow: none;
  background: none;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
  border-bottom: solid 1px #C2D4CE;
  margin-left: -3.75rem;
  margin-right: -2.5rem;
}

.invTableBlock {
  border-radius: 14px;
  border: 1px solid #C2D4CE;
  overflow: hidden;
}

.invTable {
  width: 100%;
  background: #fff;
}

.invTable tr {
  border-top: 1px solid #C2D4CE;
}

.invTable tr:first-child {
  border-top: none;
}

.invTable th {
  background: #C8F1E2;

}

.invTable tr td,
.invTable tr th {
  padding: 10px;
  text-align: center;
}

.prc_status {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 15.62px;
  text-align: left;
  padding: 5px;
}

.prc_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid #C2D4CE;
  margin: 0 -20px;
}

.prc_date {
  font-size: 1rem;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #444444;
  margin-bottom: 10px;
}

.prc_amount {
  font-size: 1rem;
  font-weight: 700;
  line-height: 23px;
  text-align: right;
  margin-bottom: 10px;
  color: #444444;
}

.prc_mdl {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 16.93px;
  text-align: left;
  color: #444444;
}

.prc_right {
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: right;
}

.prc_status {
  padding: 5px;
  border-radius: 4px;
  padding-left: 20px;
  position: relative;
}

.prc_status.pending {
  background: #FFDDDB;
  color: #F44336;
}

.prc_status.pending::before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #F44336;
  position: absolute;
  top: 10px;
  left: 5px;

}

.prc_status.paid {
  background: #BEE3D1;
  color: #137359;
}

.prc_status.paid::before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #137359;
  position: absolute;
  top: 10px;
  left: 5px;

}

.threeDots {
  position: relative;
}

.threeDots_btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.threeDots img {
  width: 20px;
}

.newuserDashboardItemBx-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.newuserDashboardItemBxMob {
  width: calc(33% - 15px);
}

@media (max-width:768px) {
  .onDesktop {
    display: none;
  }

  .onDesktopview {
    display: none;
  }

  .onMobdiv {
    display: block;
  }

  .ddnpop {
    width: 92vw !important;
    min-height: 503px;
    bottom: auto !important;
    border-radius: 1.5rem;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .invTableBlock {
    width: 100%;
    overflow-x: auto;
  }

  .msgTextBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .prime_top-card {
    flex-direction: column;
    gap: 20px;
  }

  .prime_horseImg-mob {
    display: block;
  }

  .prime_horseImg-web {
    display: none;
  }

  .star1 {
    display: none;
  }

  .star4 {
    display: none;
  }

  .errormsg {
    align-items: flex-start;
  }

  .warnigIconBox {
    width: 2rem;
    padding-top: 2px;
  }

  .closeIconBox {
    width: 4rem;
    padding-top: 6px;
  }

  .paymentPendingBox {
    flex-direction: column;
    gap: 20px;
  }

  .popmsgtxtbx {
    padding: 0;
    font-size: 11px;
  }

  .pop-msg-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .popul li {
    font-size: 13px;
  }

  .ctb {
    height: 3.2rem;
  }

  .stageBlockrow {
    flex-direction: column;
    gap: 30px;
  }

  .stageBloc {
    width: 100%;
  }

  .getEnterprise_btn {
    display: none;
  }

  .prime_top-card {
    display: flex;
    align-items: center;
    padding: 20px 30px;
  }

  .prime_top-card-text {
    text-align: center;
    font-size: 1.2rem;
  }

  .prime_st {
    height: 40px;
    width: 40px;
    position: absolute;
  }

  .prime_st2 {
    height: 35px;
    width: 35px;
    position: absolute;
  }

  .star2 {
    bottom: 5px;
    left: 6px;
  }

  .star3 {
    top: 5px;
    right: 0;
  }

  .hhdright {
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .forMobile {
    display: flex;
    padding: 7px 10px;
    gap: 4px;
    border: solid 1px #C2D4CE;
    border-radius: 12px;
    align-items: center;
  }

  .forMobile .coinimg {
    width: 22px;
  }

  .newuserDashboardRow {
    /* flex-direction: column;
    align-items: flex-start; */
    gap: 10px;
  }

  .newuserDashboardItemBx {
    width: 100%;
  }

  .newuserDashboardItemBx-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .newuserDashboardItemBxMob {
    width: calc(50% - 10px);
  }

  .creatnewbtn {
    position: fixed;
    bottom: 25px;
    left: 15px;
    right: 15px;
  }

  .stageBlockrow {
    gap: 20px;
  }

  .stageBlocCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .crdno {
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .perm {
    font-size: 1rem;
    font-weight: 400;
    line-height: 16.93px;
    text-align: center;
  }

  .acrd-fttr {
    background: none;
  }

  .pprc span {
    font-size: 2rem;
  }

  .fttrli {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    color: #444;
    list-style-type: disc;
    padding-left: 20px;
  }

  .acrd-fttr {
    padding: 15px 10px 15px 17px;
  }

  .pricingTableBlock {
    margin-bottom: 50px;
  }

  .crdmobwidth {
    width: calc(50% - 10px);
  }

  .tutorialfrontvdo {
    height: auto !important;
    margin: 0 auto;
  }
}

@media (max-width:992px) {
  .tutorialfrontvdo {
    height: auto !important;
    margin: 0 auto;
  }
}

@media (max-width: 450px) {
  .ddnpop {
    width: 92vw !important;
    min-height: 450px;
    bottom: auto !important;
    border-radius: 1.5rem;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 370px) {
  .ddnpop {
    width: 92vw !important;
    min-height: 430px;
    bottom: auto !important;
    border-radius: 1.5rem;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.card-warning {
  position: absolute;
  z-index: 33;
  top: 11px;
  right: 0;
}

.progress-info {
  z-index: 33;
}